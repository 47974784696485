import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import toast from "react-hot-toast";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    const handleLogin = () => {
        // Dummy credentials (replace with your actual logic)
        const credentials = [
            { username: 'manh', password: 'manhmanh', role: 'admin' },
            { username: 'banhang', password: 'banhang', role: 'user' },
        ];

        const user = credentials.find(
            (cred) => cred.username === username && cred.password === password
        );

        if (user) {
            // Store role and authentication token in localStorage
            localStorage.setItem('role', user.role);
            localStorage.setItem('authenticated', 'true');

            // Trigger the login function from the context
            login();
        } else {
            toast.error('Tên đăng nhập hoặc mật khẩu không đúng');
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <Form>
                <h2 className="text-center mb-4">Đăng nhập</h2>
                <Form.Group controlId="formUsername" className="mb-3">
                    <Form.Label>Tên đăng nhập</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tên đăng nhập"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="formPassword">
                    <Form.Label>Mật khẩu</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Mật khẩu"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <div className="d-grid gap-0">
                    <Button variant="primary" onClick={handleLogin} block className="mt-3">
                        Đăng nhập
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Login;
