import React from "react";
import {usePromiseTracker} from "react-promise-tracker";
import {ThreeDots} from "react-loader-spinner";

const LoadingBar = ({width= 50, height = 50}) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        <div>
            <div className="d-flex justify-content-center">
                {promiseInProgress && <ThreeDots
                    visible={true}
                    height={height}
                    width={width}
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />}
            </div>
        </div>
    );
};

export default LoadingBar;
