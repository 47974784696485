function validateEAN(ean) {
    // Remove any non-numeric characters
    const cleanedEAN = ean.replace(/\D/g, '');

    // Check if the length is 13
    if (cleanedEAN.length !== 13) {
        return false;
    }

    // Calculate the checksum
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += parseInt(cleanedEAN[i]) * (i % 2 === 0 ? 1 : 3);
    }

    const checksum = (10 - (sum % 10)) % 10;

    // Check if the last digit matches the calculated checksum
    return parseInt(cleanedEAN[12]) === checksum;
}

function playBeepSound() {
    const successSound = new Audio('/sounds/store-scanner-beep-90395.mp3');
    successSound.play().then(r => console.log(r));
}

export {
    validateEAN,
    playBeepSound
}
