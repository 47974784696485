import React, {useEffect, useState} from 'react';
import {Form, Button, InputGroup} from 'react-bootstrap';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';
import {TbBarcode, TbBarcodeOff} from "react-icons/tb";
import { IoSearch } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import {playBeepSound, validateEAN} from "../utils";
import {useParams} from "react-router-dom";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import LoadingBar from "./LoadingBar";
import ImageModal from "./ImageModal";

const AddEditProduct = () => {
    const { id } = useParams();

    const [eanCode, setEanCode] = useState('');
    const [label, setLabel] = useState('');
    const [brand, setBrand] = useState('');
    const [quantity, setQuantity] = useState(null);
    const [price1, setPrice1] = useState(null);
    const [price2, setPrice2] = useState(null);
    const [composition, setComposition] = useState('');
    const [description, setDescription] = useState('');
    const [showScanner, setShowScanner] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [productKeyword, setProductKeyword] = useState('');
    const [productsByName, setProductsByName] = useState([]);

    const {promiseInProgress} = usePromiseTracker();
    const [showBigImage, setShowBigImage] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (productKeyword !== '') {
                handleSearchByName(productKeyword);
            }

        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [productKeyword])

    // Load product information
    const [product, setProduct] = useState({});
    useEffect(async () => {
        if (id) {
            await trackPromise(
                fetch(`${process.env.REACT_APP_API_URL}/inventory-products/${id}`)
                    .then(response => response.json())
                    .then(data => {
                        setProduct(data);
                    })
                    .catch(error => {
                        console.error('Error fetching product information:', error);
                    })
            )
        } else {
            resetFields();
        }
    }, [id]);

    useEffect(() => {
        // Mode create
        if (product) {
            setEanCode(product.sku);
            setLabel(product.label);
            setBrand(product.brand);
            setQuantity(product.quantity);
            setPrice1(product.price1);
            setPrice2(product.price2);
            setComposition(product.composition);
            setDescription(product.description);
        }
    }, [product]);

    const resetFields = () => {
        setEanCode('');
        setLabel('');
        setBrand('');
        setQuantity('');
        setPrice1('');
        setPrice2('');
        setDescription('');
        setComposition('');
        setProductKeyword('');
        setValidationErrors({});
    }

    const handleScan = (barcode) => {
        setEanCode(barcode);
        setShowScanner(false);
        fetchProduct(barcode);
    };

    const fetchProduct = async (eanCode) => {
        if (!validateEAN(eanCode)) {
            return;
        }

        await trackPromise(
            fetch(`${process.env.REACT_APP_API_URL}/products?keyword=${eanCode}`)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 3) {
                        const product = data[0];
                        setLabel(product.label || '');
                        setBrand(product.brand || '');
                        setComposition(product.composition || '');
                    } else {
                        toast.error("Không tìm thấy sản phẩm với mã EAN này");
                        resetFields();
                    }
                })
                .catch(error => {
                    console.error('Error fetching product information:', error);
                })
        )
    }

    const validateForm = () => {
        const errors = {};

        if (!label) {
            errors.label = 'Tên sản phẩm chưa điền';
        }

        if (!brand) {
            errors.brand = 'Nhãn hiệu chưa điền';
        }

        if (quantity === '' || quantity === null) {
            errors.quantity = 'Số lượng chưa điền';
        }

        if (price1 === '' || price1 === null) {
            errors.price1 = 'Giá giao chưa điền';
        }

        if (price2 === '' || price2 === null) {
            errors.price2 = 'Giá bán chưa điền';
        }

        setValidationErrors(errors);

        // Return true if there are no validation errors, otherwise false
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form before submitting
        if (validateForm()) {
            // Make an API POST request to add the product : http://localhost:8001/inventory-products/save
            await trackPromise(
                fetch(`${process.env.REACT_APP_API_URL}/inventory-products/save`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        sku: eanCode,
                        label,
                        brand,
                        quantity: parseInt(quantity),
                        price1: parseInt(price1),
                        price2: parseInt(price2),
                        description,
                        composition,
                    }),
                })
                    .then((data) => {
                        if (data.ok) {
                            toast.success("Sản phẩm đã được lưu thành công");
                            //resetFields();
                        } else {
                            toast.error("Có lỗi xảy ra khi lưu sản phẩm");
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching product information:', error);
                        toast.error(error.message);
                    })
            )

        }
    };

    const handleSearchByName = async (keyword) => {
        if (!keyword || id) return;

        await trackPromise(
            fetch(`${process.env.REACT_APP_API_URL}/products?keyword=${keyword}`)
                .then(response => response.json())
                .then(data => {
                    setProductsByName(data)
                })
                .catch(error => {
                    console.error('Error fetching product information:', error);
                })
        )
    }

    const leftColClass = eanCode ? 'col-9' : 'col-12';
    const rightColClass = eanCode ? 'col-3' : 'd-none'

    let bigImage = product.image;
    bigImage = bigImage?.replace('width=90&height=90', 'width=500&height=500');
    bigImage = bigImage?.replace('&hei=90', '&hei=500');

    return (
        <div className="p-3 pt-1">
            <div className="row">
                <div className="col-8">
                    <h1 className="mb-0 mt-0">{id ? 'Sửa sản phẩm' : 'Thêm sản phẩm'}</h1>
                </div>
                <div className="col-4">
                    {promiseInProgress && <LoadingBar height={30} width={50} />}
                </div>
            </div>
            <div>{label}</div>
            <hr className="mb-0 mt-1" />
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className={leftColClass}>
                        <Form.Group controlId="eanCode" id="eanCode" className="position-relative form-group">
                            <Form.Label>Mã EAN</Form.Label>
                            <Form.Control
                                type="text" value={eanCode}
                                onChange={(e) => {
                                    setEanCode(e.target.value);
                                    fetchProduct(e.target.value);
                                }}
                                disabled={true}
                                isInvalid={!!validationErrors.eanCode}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.eanCode}
                            </Form.Control.Feedback>
                            {showScanner && <BarcodeScannerComponent
                                //onUpdate={(err, result) => onUpdateScreen(err, result)}
                                onUpdate={(err, result) => {
                                    if (!err && result && result.text) {
                                        playBeepSound();
                                        handleScan(result.text)
                                    }
                                }}
                                height="100%"
                                width="100%"
                            />}
                            {!id && <span
                                id="eanCodeScanner"
                                className="ml-2"
                                style={{cursor: 'pointer'}}
                                onClick={() => setShowScanner(!showScanner)}
                            >
                                {showScanner ? <TbBarcodeOff size={30} /> : <TbBarcode size={30} />}
                            </span>}

                        </Form.Group>
                        <Form.Group controlId="label" className="form-group">
                            <Form.Label>Tên sản phẩm</Form.Label>
                            <InputGroup className="mb-0">
                                <Form.Control
                                    type="text"
                                    value={label}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setLabel(value)
                                        setProductKeyword(value);
                                    }}
                                    isInvalid={!!validationErrors.label}
                                />
                                {!id && !productKeyword && <InputGroup.Text><IoSearch size={24} /></InputGroup.Text>}
                                {!id && productKeyword && <InputGroup.Text>
                                    <IoCloseCircleOutline size={24} onClick={() => resetFields()} />
                                </InputGroup.Text>}
                            </InputGroup>
                            {productsByName.length > 0 && productKeyword &&
                            <div id="productsByName" className="position-relative">
                                <span id="productsByNameClose" onClick={() => setProductsByName([])}><IoCloseCircleOutline size={25} /></span>
                                {productsByName.map((product, index) => {
                                    return (
                                        <div key={index} className="productByName" onClick={() => {
                                            setProduct(product);
                                            setProductsByName([]);
                                        }}>
                                            <div className="row">
                                                <div className="col-2 text-center ps-0">
                                                    <img src={product.image} alt="" height={50} />
                                                </div>
                                                <div className="col-10">
                                                    <div>{product.label}</div>
                                                    <div className="text-black-50">{product.brand}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>}

                            <Form.Control.Feedback type="invalid">
                                {validationErrors.label}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className={`${rightColClass} pt-5 text-center`}>
                        <img src={product.image} className="" alt="" height={90} onClick={() => setShowBigImage(true)} />
                        <ImageModal
                            showImage={showBigImage}
                            setShowImage={setShowBigImage}
                            imageUrl={bigImage}
                        />
                    </div>
                </div>

                <Form.Group controlId="brand" className="form-group">
                    <Form.Label>Nhãn hiệu</Form.Label>
                    <Form.Control
                        type="text"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        isInvalid={!!validationErrors.brand}
                    />
                    <Form.Control.Feedback type="invalid">
                        {validationErrors.brand}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="composition" className="form-group">
                    <Form.Label>Mô tả</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="composition" className="form-group">
                    <Form.Label>Thành phần</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={composition}
                        onChange={(e) => setComposition(e.target.value)}
                    />
                </Form.Group>
                <div className="row">
                    <div className="col-4">
                        <Form.Group controlId="brand" className="form-group">
                            <Form.Label>Số lượng</Form.Label>
                            <Form.Control
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                isInvalid={!!validationErrors.quantity}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.quantity}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-4">
                        <Form.Group controlId="brand" className="form-group">
                            <Form.Label>Giá giao</Form.Label>
                            <Form.Control
                                type="number"
                                value={price1}
                                onChange={(e) => setPrice1(e.target.value)}
                                isInvalid={!!validationErrors.price1}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.price1}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-4">
                        <Form.Group controlId="brand" className="form-group">
                            <Form.Label>Giá bán</Form.Label>
                            <Form.Control
                                type="number"
                                value={price2}
                                onChange={(e) => setPrice2(e.target.value)}
                                isInvalid={!!validationErrors.price2}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.price2}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div id="submit-btn-block" className="d-flex justify-content-end mt-2">
                    <Button variant="primary" type="submit" className="mt-2">
                        Lưu sản phẩm
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default AddEditProduct;
