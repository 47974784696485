import React, {useEffect, useState} from 'react';
import {Badge} from "react-bootstrap";
import Header from "./Header";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import toast from "react-hot-toast";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdEditNote, MdDeleteSweep } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import Confirm from "./Confirm";
import {Link} from "react-router-dom";
import {playBeepSound} from "../utils";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import LoadingBar from "./LoadingBar";
import {useAuth} from "../context/AuthContext";
import ImageModal from "./ImageModal";

const Inventory = () => {
    const {role} = useAuth();
    const { promiseInProgress } = usePromiseTracker();

    const [searchProducts, setSearchProducts] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [show, setShow] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);

    const onUpdateScreen = (err, result) => {
        if (!err && result && result.text) {
            playBeepSound();
            setSearchKeyword(result.text);
            setShow(false);
        }
    };

    const loadProducts = async () => {
        await trackPromise(fetch(`${process.env.REACT_APP_API_URL}/inventory-products`)
            .then(r => r.json())
            .then(products => {
                setSearchProducts(products);
            })
            .catch(error => {
                toast('Lỗi khi tải danh sách sản phẩm');
                console.error('Error fetching inventory products:', error);
            }).then(r => r))
    }

    useEffect(() => {
        loadProducts();
    }, []);

    /*useEffect(() => {
        // Reload products when search keyword cleared
        console.log('searchKeyword', searchKeyword);
        if (!searchKeyword) {
            loadProducts();
        }
    }, [searchKeyword])*/

    const searchTitle = searchKeyword ? `Kết quả tìm kiếm cho "${searchKeyword}"` : 'Danh sách sản phẩm';

    const handleDelete = (inventoryProductId, label) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirm
                        title='Xác nhận xóa sản phẩm'
                        description={`Bạn muốn xóa sản phẩm "${label}"?`}
                        cancelLabel='Không xóa'
                        confirmLabel='Xóa sản phẩm'
                        onClose={onClose}
                        onConfirm={() => {
                            // Call API to delete document
                            fetch(`${process.env.REACT_APP_API_URL}/inventory-products/${inventoryProductId}`, {
                                method: 'DELETE'
                            })
                                .then(r => r.json())
                                .then(data => {
                                    toast.success('Xóa sản phẩm thành công');
                                    loadProducts();
                                })
                        }}
                    />
                );
            }
        });
    }

    const handleViewImage = (product) => {
        let bigImage = product.image;
        bigImage = bigImage?.replace('width=90&height=90', 'width=500&height=500');
        bigImage = bigImage?.replace('&hei=90', '&hei=500');

        product.image = bigImage;

        setCurrentProduct(product);
        setShowImage(true);
    }

    return (
        <div>
            <Header
                setSearchProducts={setSearchProducts}
                show={show}
                setShow={setShow}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
            />
            <LoadingBar height={30}/>
            {currentProduct && <ImageModal imageUrl={currentProduct.image} setShowImage={setShowImage} showImage={showImage}  label={currentProduct.label}/>}
            <div className="p-3 pt-1">
                {show && (
                    <BarcodeScannerComponent
                        width="100%"
                        height="100%"
                        onUpdate={(err, result) => onUpdateScreen(err, result)}
                    />
                )}

                {!promiseInProgress &&  <h4 className="mt-2">{searchTitle}</h4>}
                {searchProducts?.map((product) => {
                    let bigImage = product.image;
                    bigImage = bigImage?.replace('width=90&height=90', 'width=500&height=500');
                    bigImage = bigImage?.replace('&hei=90', '&hei=500');
                    //let imageUrl = '/bottle.jpg';

                    // 0 danger, 3 warning, from 4 success
                    const quantity = product.quantity;
                    const availableBg = quantity === 0 ? 'danger' : quantity < 4 ? 'warning' : 'success';
                    const availableText = quantity === 0 ? 'Hết hàng' : `Còn: ${quantity}`;

                    return (
                        <div key={product.sku} className="result-item">
                            <div className="row">
                                <div className="col-2">
                                    <div className="text-center">
                                        <img src={product.image} alt="img" className="img-fluid" onClick={() => handleViewImage(product)} />
                                    </div>
                                </div>
                                <div className="col-10 position-relative">
                                    <div className="row">
                                        <div className={role === 'admin' ? 'col-10' : 'col-12'}>
                                            <div className="item-label">{product.label}</div>
                                            <div className="item-brand text-black-50">{product.brand}</div>
                                        </div>
                                        <div className={role === 'admin' ? 'col-2' : ''}>
                                            {role === 'admin' &&
                                            <div className="item-actions">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-secondary"></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                            <Link to={`/add-edit-product/${product.sku}`} className="text-dark text-decoration-none">
                                                                <MdEditNote size={25} /> Cập nhật
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="text-danger" onClick={() => {handleDelete(product.sku, product.label)}}>
                                                            <MdDeleteSweep size={25} /> Xóa sản phẩm
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="text-success item-info">
                                        <Badge bg={availableBg}>{availableText}</Badge>
                                        <Badge bg="secondary">Giá giao: {product.price1}</Badge>
                                        <Badge bg="secondary">Giá bán: {product.price2}</Badge>
                                    </div>
                                </div>

                            </div>
                        </div>
                    );
                })}
                {searchProducts.length === 0 && !promiseInProgress && <div className="text-center mt-3">Không tìm thấy sản phẩm nào</div>}
            </div>
        </div>
    );
};

export default Inventory;
