import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { LuWarehouse } from "react-icons/lu";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import {useAuth} from "../context/AuthContext";

const Footer = () => {
    const location = useLocation();
    const {role} = useAuth();

    return (
        <div id="footer" className="font fixed-bottom">
            {role === 'admin' && (
                <ButtonGroup className="d-flex justify-content-between">
                    <Link to="/" className={`btn ${location.pathname === '/' ? 'btn-primary': 'btn-light'}`}>
                        <div><LuWarehouse size={18} /> Kho hàng</div>
                    </Link>
                    <Link disabled to="/add-edit-product" className={`btn ${location.pathname.includes('/add-edit-product') ? 'btn-primary': 'btn-light'}`}>
                        <div><IoIosAddCircleOutline size={18} /> Thêm sản phẩm</div>
                    </Link>
                </ButtonGroup>
            )}
        </div>
    );
};

export default Footer;
