import React from 'react';
import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(
        localStorage.getItem('authenticated') === 'true'
    );
    const [role, setRole] = useState(localStorage.getItem('role') || '');

    const login = () => {
        setAuthenticated(true);
        setRole(localStorage.getItem('role') || '');
    };

    const logout = () => {
        // Remove role and authentication token from localStorage
        localStorage.removeItem('role');
        localStorage.removeItem('authenticated');
        setAuthenticated(false);
        setRole('');
    };

    return (
        <AuthContext.Provider value={{ authenticated, role, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
