import React from 'react';
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import PropTypes from "prop-types";

const ImageModal = ({showImage, setShowImage, label, imageUrl}) => {
    return (
        <Modal show={showImage} onHide={() => setShowImage(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Larger image displayed in the modal */}
                <img
                    src={imageUrl}
                    alt="img"
                    style={{ width: '100%' }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {setShowImage(false)}}>
                    Đóng lại
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ImageModal.propTypes = {
    showImage: PropTypes.bool.isRequired,
    setShowImage: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired
};

export default ImageModal;
