import React, {useEffect} from 'react';
import { Navbar, Form, FormControl } from 'react-bootstrap';
import { TbBarcodeOff, TbBarcode } from "react-icons/tb";
import {trackPromise} from "react-promise-tracker";
import toast from "react-hot-toast";
import Dropdown from "react-bootstrap/Dropdown";
import {MdDeleteSweep} from "react-icons/md";
import {useAuth} from "../context/AuthContext";

const Header = ({setSearchProducts, show, setShow, searchKeyword, setSearchKeyword}) => {
    const {logout, role} = useAuth();

    const handleSearch = async () => {
        // Perform the API call to fetch search results
        await trackPromise(fetch(`${process.env.REACT_APP_API_URL}/inventory-products?keyword=${searchKeyword}`)
            .then(r => r.json())
            .then(products => {
                setSearchProducts(products);
            })
            .catch(error => {
                toast('Lỗi khi tải danh sách sản phẩm');
                console.error('Error fetching inventory products:', error);
            }).then(r => r))
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchKeyword !== null) {
                handleSearch();
            }

        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchKeyword, setSearchProducts])

    const handleSubmit = (e) => {
        e.preventDefault();

        const value = e.target.value;

        if (value !== undefined) {
            setSearchKeyword(e.target.value);
        }
    };

    return (
        <div>
            <Navbar bg="primary" variant="dark" sticky="top" className="ps-2 pe-2">
                <Form className="w-100 position-relative" onSubmit={handleSubmit}>
                    <FormControl
                        type="text"
                        placeholder="Tìm kiếm sản phẩm trong kho..."
                        className="mr-sm-2 w-100" id="searchInput"
                        style={{ width: '100%' }}
                        value={searchKeyword}
                        onChange={(e) => {
                            e.preventDefault();
                            setSearchKeyword(e.target.value);
                        }}
                    />
                    <span id="barcodeIcon" className="ml-2" onClick={() => setShow(!show)}>
                        {show ? <TbBarcodeOff size={30} /> : <TbBarcode size={30} />}
                    </span>
                </Form>
                <div className="ms-2">
                    <Dropdown align="end">
                        <Dropdown.Toggle variant="outline-secondary"/>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                Chào {role === 'user' ? 'cộng tác viên' : role}!
                            </Dropdown.Item>
                            <Dropdown.Item className="text-danger" onClick={() => {logout()}}>
                                <MdDeleteSweep size={25} /> Đăng xuất
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Navbar>
        </div>

    );
};

export default Header;
