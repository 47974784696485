import React from 'react';
import './App.css';
import Inventory from "./components/Inventory";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AddEditProduct from "./components/AddEditProduct";
import {Toaster} from "react-hot-toast";
import Login from "./components/Login";
import {AuthProvider, useAuth} from "./context/AuthContext";

const App = () => {
    return (
        <AuthProvider>
            <MainApp />
        </AuthProvider>
    );
};

const MainApp = () => {
    const { authenticated } = useAuth();

    return (
        <div>
            {authenticated ? (
                <Router>
                    <div className="d-flex flex-column">
                        <div className="flex-grow-1">
                            <Routes>
                                <Route path="/add-edit-product/:id" element={<AddEditProduct/>}/>
                                <Route path="/add-edit-product" element={<AddEditProduct/>}/>
                                <Route exact path="/" element={<Inventory/>}/>
                            </Routes>
                        </div>
                        <Footer />
                    </div>
                </Router>
            ) : (
                <Login />
            )}
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    );
};



export default App;
