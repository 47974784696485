import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

const Confirm = ({title, description, cancelLabel, confirmLabel, onClose, onConfirm}) => {
  return (
    <div>
      <Modal
          show={true}
          backdrop="static"
          onHide={() => {onClose()}}
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {description}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            onClose()
          }}>
            {cancelLabel}
          </Button>
          <Button variant="danger" onClick={() => {
            onConfirm();
            onClose();
          }}>{confirmLabel}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Confirm;
